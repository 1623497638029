import { OrderForm } from '@/types/order';
import { defineStore } from 'pinia';

type State = {
  form: ReturnType<typeof defaultForm>
  showProduct: boolean,
};

const defaultForm = (): OrderForm => ({
  country: 'US',
  logisticsCode: '',
  declareAmount: null,
  isAgree: true,
  expressId: '',
  address: null,
  remark: '',
  products: [],
  selectRemark: [],
});
export const useStore = defineStore('order', {
  state: (): State => ({
    form: defaultForm(),
    showProduct: false,
  }),
  actions: {
    async resetForm() {
      const defaultFormValue = defaultForm();
      Object.keys(defaultFormValue).forEach((item) => {
        this.setForm(item, defaultFormValue[item]);
      });
      console.log(this.form);
    },
    setForm(key: string, value: any) {
      this.form[key] = value;
    },
  },
});
