/** 中转仓状态 */
export enum WareHouseState {
  Normol = 1
}
/** 中转仓列表item */
export type WareHouseItem = {
  address1: string,
  address2: string,
  city: string,
  country: string,
  created: string | null,
  id: number,
  managerName: string,
  name: string,
  phone: string,
  state: string,
  status: WareHouseState,
  title: string,
  updated: string | null,
  userId: number,
  contactAddress: string,
  contactName: string,
};

/** 是否默认地址 */
export enum IsDefaultUserAddress {
  Default = 1,
  Normal = 0
}

/** 地址状态 */
export enum AddressState{
  Normal = 1
}

/** 地址 */
export type AddressItem = {
  address1: string,
  address2: string,
  city: string,
  country: string,
  created: number,
  firstname: string,
  id: number,
  isDefault: IsDefaultUserAddress,
  lastname: string,
  mobile: string,
  state: string,
  status: AddressState,
  updated: number,
  user: number,
  userId: number,
  zipcode: string,
};

/** isSelectMode */
export enum AddressListMode{
  Select = '0',
  Normal = '1'
}
