import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44ed7df0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-option" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Picker = _resolveComponent("Picker")!

  return (_openBlock(), _createBlock(_component_Picker, {
    "is-hide-header": true,
    options: _ctx.pickerOptions,
    "is-show-picker": _ctx.isShowPicker,
    modelValue: _ctx.selfValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selfValue) = $event)),
    onCancel: _ctx.onClosePicker
  }, {
    item: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(option.label) + " ", 1),
        _createElementVNode("img", {
          class: "icon",
          src: option.icon,
          alt: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    footer: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "g-button g-button-primary cancel",
          onClick: option.cancel
        }, " 取消 ", 8, _hoisted_4),
        _createElementVNode("div", {
          class: "g-button g-button-primary confirm",
          onClick: option.confirm
        }, " 确认 ", 8, _hoisted_5)
      ])
    ]),
    _: 1
  }, 8, ["options", "is-show-picker", "modelValue", "onCancel"]))
}