import { request } from '@/libs/request';
import { OrderForm, OrderCommiteRes, PckInfoRes } from '@/types/order';
import { formatDate } from '@/libs/utils';
/** 下单 */
export const confirmOrder = async (
  form: OrderForm
): Promise<OrderCommiteRes> => {
  if (!form.address) {
    throw '未选择地址';
  }
  const res = await request({
    url: '/v1/package',
    method: 'POST',
    data: {
      express_id: form.expressId,
      ship_from: {
        firstname: form.address.firstname,
        lastname: form.address.lastname || ' ',
        mobile: form.address.mobile,
        country: form.address.country,
        state: form.address.state,
        city: form.address.city,
        zipcode: form.address.zipcode,
        address1: form.address.address1,
        address2: form.address.address2,
      },
      logistics_code: form.logisticsCode,
      declare_amount: form.declareAmount,
      // stockin_num: form.stockin_num,
      note: form.remark,
      products: form.products || undefined,
    },
  });
  return {
    address: res.address,
    created: res.created,
    expressType: res.express_type,
    id: res.id,
    mobile: res.mobile,
    name: res.name,
  };
};
// 查询单信息
export const getPkgInfo = async (id): Promise<PckInfoRes> => {
  const res = await request({
    url: `/v1/package/${id}`,
    method: 'GET',
  });
  return {
    address1: res.address1, // "eee"
    address2: res.address2, // "eeee"
    basePrice: res.base_price, // null
    city: res.city, // "eeee"
    country: res.country, // "US"
    created: formatDate('yyyy-mm-dd h:i:s', res.created), // 1644654498
    declareAmount: res.declare_amount, // 1234
    declarePrice: res.declare_price, // null
    express: res.express, // {title: "美国内置电空运专线"}
    firstname: res.firstname, // "eeee"
    id: res.id, // 1051
    lastname: res.lastname, // " "
    logisticsCode: res.logistics_code, // "ddd"
    mobile: res.mobile, // "eeee"
    note: res.note, // "nihap"
    packageAttr: res.package_attr, // []
    packageNum: res.package_num, // "GLP0000001051"
    payed: res.payed, // null
    price: res.price, // null
    state: res.state, // "AL"
    status: res.status, // 2
    statusPay: res.status_pay, // 2
    statusTrack: res.status_track, // 2
    userId: res.user_id, // 3072
    zipcode: res.zipcode, // "eee"
  };
};

/** 更新订单国内快递单号 */
export const updateLogisticsCode = async ({
  id,
  logisticsCode,
}: Pick<PckInfoRes, 'id' | 'logisticsCode'>) => {
  const res = await request({
    url: `/v1/package/${id}/logisticscode`,
    method: 'PUT',
    params: { logistics_code: logisticsCode },
  });
  return res;
};

/** 获取订单列表 */
export const getOrderList = async (
  data
): Promise<{
  list: any[];
  unpaidTotalNum: number;
}> => {
  const res = await request({
    url: '/v1/package',
    method: 'GET',
    params: {
      page_index: data.pageIndex,
      page_size: data.pageSize,
      search: data.search,
      get_unpaid_total: 1,
      status_pay: data.statusPay,
    },
  });
  const { list } = res;
  const packages = list.map((item) => ({
    ...item,
    checked: false,
    created: formatDate('yyyy-mm-dd h:i:s', item.created),
  }));

  return {
    list: packages,
    unpaidTotalNum: res.unpaid_total,
  };
};
/**
 *  支付订单确认
 * @param postData
 * @returns
 */
export const confirmPackageOrder = async (postData: any): Promise<any> => {
  const res = await request({
    url: '/v1/package/confirm/package-order',
    method: 'POST',
    data: postData,
  });
  return res;
};
/**
 *
 * @param postData
 * @returns
 */
export const submitPackageOrder = async (postData: any): Promise<any> => {
  const res = await request({
    url: '/v1/payment/pay-order/submit',
    method: 'POST',
    data: postData,
  });
  return res;
};
