import { request } from '@/libs/request';
import { WareHouseItem, AddressItem } from '@/types/address';

/** 用户默认地址 */
export const getDefaultAddress = async (): Promise<AddressItem> => {
  const data = await request({
    url: '/v1/address-book/default',
    method: 'GET',
  });
  return data;
};

/** 中转仓列表 */
export const getTransferWareHouseList = async (): Promise<WareHouseItem[]> => {
  const data = await request({
    url: '/v1/transfer-warehouse/list',
    method: 'GET',
  });
  return data.map(item => ({
    ...item,
    managerName: item.manager_name,
    userId: item.user_id,
    contactName: item.name ? item.name : item.title,
    contactAddress: `${item.state}${item.city}${item.address1}${item.address2 || ''}`,
  }));
};

/** 新增地址 */
export const addUserAddress = async (data) => {
  const res = await request({
    url: '/v1/address-book',
    method: 'POST',
    data: {
      firstname: data.firstname,
      lastname: data.lastname,
      mobile: data.mobile,
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      address1: data.address1,
      address2: data.address2,
      is_default: data.isDefault ? 1 : 0,
    },
  });

  return res;
};

/** 修改地址 */
export const editUserAddress = async (data: AddressItem) => {
  const res = await request({
    url: `/v1/address-book/${data.id}`,
    method: 'PUT',
    data: {
      firstname: data.firstname,
      lastname: data.lastname,
      mobile: data.mobile,
      country: data.country,
      state: data.state,
      city: data.city,
      zipcode: data.zipcode,
      address1: data.address1,
      address2: data.address2,
      is_default: data.isDefault,
    },
  });

  return res;
};

/** 获取地址列表 */
export const getAddressList = async (params: {
  pageSize: number,
  pageIndex: number
}): Promise<AddressItem[]> => {
  const res = await request({
    url: '/v1/address-book',
    method: 'GET',
    params: {
      page_size: params.pageSize,
      page_index: params.pageIndex,
    },
  });
  return res.map(item => ({
    ...item,
    isDefault: item.is_default,
    userId: item.user_id,
  }));
};

export const getAddressDetailById = async (id: string): Promise<AddressItem> => {
  const res = await request({
    url: `/v1/address-book/${id}`,
    method: 'GET',
  });
  return { ...res, isDefault: res.is_default, userId: res.user_id };
};
