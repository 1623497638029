
import { defineComponent, ref, watch, PropType } from 'vue';
import { VueScrollPicker, VueScrollPickerOption } from 'vue-scroll-picker';
import 'vue-scroll-picker/lib/style.css';

export default defineComponent({
  name: 'Picker',
  components: {
    VueScrollPicker,
  },
  emits: ['update:modelValue', 'cancel'],
  props: {
    isHideHeader: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array as PropType<VueScrollPickerOption[]>,
      required: true,
      defalut: [],
    },
    modelValue: {
      type: String,
      default: '',
    },
    isShowPicker: {
      type: Boolean,
      required: true,
    },
  },
  setup: (prop, ctx) => {
    const selfValue = ref('');

    watch(prop, (newVal) => {
      selfValue.value = newVal.modelValue;
    }, { deep: true });

    return {
      selfValue,
      confirm: () => {
        ctx.emit('update:modelValue', selfValue.value);
        ctx.emit('cancel');
      },
      cancel: () => {
        ctx.emit('cancel');
      },
    };
  },
});
