export const commonErrHandle = (err) => {
  if (err) {
    if (err === 'unlogin') {
      throw 'unlogin';
    } else {
      return true;
    }
  } else {
    return false;
  }
};
