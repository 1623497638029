import { useRouter } from 'vue-router';
import { AddressListMode } from '@/types/address';
import { OrderCompleteQuery } from '@/types/router';

/** 所有的跳转方法 */
export const useJumpPage = () => {
  const router = useRouter();
  /** 跳转到绑定手机页面 */
  // const navBindMobile = () => {
  //   router.push({
  //     name: 'BindMobile',
  //   });
  // };
  /** 跳转到价格测算页面 */
  const navExpressRate = () => {
    router.push({
      name: 'ExpressRate',
    });
  };
  /** 跳转到地址列表 */
  const navAddressList = ({ mode }: { mode: AddressListMode }) => {
    router.push({
      name: 'AddressList',
      query: {
        mode,
      },
    });
  };
  /**  */
  const navProductList = () => {
    router.push({
      name: 'ProductList',
    });
  };
  /**  */
  const navProductEdit = (index?: string) => {
    router.push({
      name: 'ProductEdit',
      query: {
        index,
      },
    });
  };
  /** 跳转到修改地址页面 */
  const navAddressDetail = (id?: string) => {
    router.push({
      name: 'AddressEdit',
      query: {
        addressId: id,
      },
    });
  };
  /** 跳转到修改订单协议页面 */
  const navOrderProtocol = () => {
    router.push({
      name: 'OrderProtocol',
    });
  };
  /** 跳转到订单列表页面 */
  const navOrderList = () => {
    router.push({
      name: 'OrderList',
    });
  };
  /** 跳转到下单成功页面 */
  const navOrderCompleteList = (data: OrderCompleteQuery) => {
    router.push({
      name: 'OrderComplete',
      query: data,
    });
  };
  /** 跳转到下单成功页面 */
  const navSubPkg = (id: string) => {
    router.push({
      name: 'OrderSub',
      query: { id },
    });
  };
  /** 跳转到下单页面 */
  const navOrder = (
    query:
      | {
          expressId: string;
          country: string;
        }
      | undefined
  ) => {
    router.push({
      name: 'Order',
      query,
    });
  };
  /** 跳转到订单详情页面 */
  const navOrderDetail = (id) => {
    router.push({
      name: 'OrderDetail',
      query: {
        id,
      },
    });
  };
  /** 跳转到交易记录详情页面 */
  const navTradeList = () => {
    router.push({
      name: 'TradeList',
    });
  };
  /** 跳转到交易记录详情页面 */
  const navRule = () => {
    router.push({
      name: 'Rule',
    });
  };
  /** 跳转到中专仓列表 */
  const navWareHouseList = () => {
    router.push({
      name: 'WareHouseList',
    });
  };
  /** navMyTeam */
  const navMyTeam = () => {
    router.push({
      name: 'MyTeam',
    });
  };
  /** navLogin */
  const navLogin = () => {
    router.push({
      name: 'Login',
      query: {
        redirect: router.currentRoute.value.fullPath,
      },
    });
  };
  const replaceCharge = () => {
    router.push({
      name: 'Charge',
    });
  };
  const navMine = () => {
    router.push({
      name: 'Mine',
    });
  };
  /** navMyTeam */
  const navMyCoupon = () => {
    router.push({
      name: 'MyCoupon',
    });
  };
  /** navToShare */
  const navToShare = () => {
    router.push({
      name: 'share',
    });
  };
  const navToCouponDetail = (couponId: string) => {
    router.push({
      name: 'CouponDetail',
      query: {
        id: couponId,
      },
    });
  };
  const navToOrderComfirm = (packageIds: string) => {
    router.push({
      name: 'OrderComfirm',
      query: {
        ids: packageIds,
      },
    });
  };
  const navToOrderCheckout = (payOrderId: number) => {
    router.push({
      name: 'OrderCheckout',
      query: {
        id: payOrderId,
      },
    });
  };
  return {
    navLogin,
    // navBindMobile,
    navProductEdit,
    navProductList,
    navExpressRate,
    navAddressList,
    navAddressDetail,
    navOrderProtocol,
    navOrderList,
    navOrderCompleteList,
    navOrder,
    navOrderDetail,
    navSubPkg,
    navTradeList,
    navRule,
    navWareHouseList,
    navMyTeam,
    replaceCharge,
    navMine,
    navMyCoupon,
    navToShare,
    navToCouponDetail,
    navToOrderComfirm,
    navToOrderCheckout,
  };
};
