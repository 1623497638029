
import { defineComponent, watch, ref } from 'vue';
import Picker from '@/components/common/picker.vue';
import { useCountry } from '@/hooks/country';

export default defineComponent({
  name: 'CountryPicker',
  components: {
    Picker,
  },
  props: {
    isShowPicker: Boolean,
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel', 'update:modelValue'],
  setup(prop, ctx) {
    const { pickerOptions } = useCountry();
    const selfValue = ref('');

    watch(
      prop,
      (newVal) => {
        selfValue.value = newVal.modelValue;
      },
      { deep: true }
    );

    watch(
      selfValue,
      (newVal) => {
        console.log(newVal);
        ctx.emit('update:modelValue', newVal);
      },
      { deep: true }
    );

    return {
      selfValue,
      pickerOptions,
      onClosePicker: () => {
        ctx.emit('cancel');
      },
    };
  },
});
