import { addUserAddress, editUserAddress, getDefaultAddress, getTransferWareHouseList, getAddressList, getAddressDetailById } from '@/api/address';
import { AddressItem, AddressListMode, AddressState, IsDefaultUserAddress, WareHouseItem } from '@/types/address';
import { onBeforeMount, Ref, ref, computed } from 'vue';
import { to } from './to';
import { useClipboard, useUrlSearchParams } from '@vueuse/core';
import { useCountry } from '@/hooks/country';
import Schema from 'async-validator';
import { useRouter } from 'vue-router';
import { useOrder } from '@/hooks/order';
import { addressDescriptor } from '@/config/form-validator-descriptor';
import { useAddressStore } from '@/stores/module/address';
import { storeToRefs } from 'pinia';
import { useToast } from '@/hooks/toast';
import { useDialog } from '@/hooks/dialog';
import { commonErrHandle } from './errHandle';
export const fetchDefaultAddress = getDefaultAddress;

export const fetchTransferWareHouseList = getTransferWareHouseList;

export const getAddressDetail = address => `${address.address1} ${address.address2 || ''} , ${address.city} , ${address.state} ${address.zipcode}, ${address.country}`;

/** 复制中转仓信息 */
export const copyAddress = (info: WareHouseItem) => {
  const { showToast } = useToast();
  const address = [info.state, info.city, info.address1, info.address2].join('');
  const copyTarget = ref(`姓名：${info.name ? info.name : info.title}\n联系电话：${info.phone}\n仓库地址：${address}\n`);
  const { copy, isSupported } = useClipboard({ source: copyTarget });
  if (!isSupported) {
    return alert('当前设备不支持');
  }
  copy();
  showToast('复制成功');
};

/** useWareHouse */
export const useWareHouse = () => {
  const { showDialog } = useDialog();
  const warehouse: Ref<WareHouseItem[]> = ref([]);
  const fetchWareHouseList = (async () => {
    const [err, res] = await to(getTransferWareHouseList());
    if (commonErrHandle(err)) {
      showDialog({ content: '获取中专仓列表失败' });
    }
    warehouse.value = res;
  });
  return {
    warehouse,
    copyAddress,
    fetchWareHouseList,
  };
};
/** useDefaultAddress */
export const useDefaultAddress = () => {
  const store = useAddressStore();
  const { fetchDefaultAddress } = store;
  const { defaultAddress } = storeToRefs(store);
  const hasAddress = computed(() => Boolean(defaultAddress.value));
  const defaultAddressDetail = computed(() => {
    if (!hasAddress.value) {
      return '';
    }
    return getAddressDetail(defaultAddress.value);
  });
  return {
    fetchDefaultAddress,
    defaultAddress,
    hasAddress,
    defaultAddressDetail,
  };
};

const getDefaulfFormAddress = (): AddressItem => ({
  isDefault: IsDefaultUserAddress.Default,
  id: 0,
  firstname: '',
  lastname: '',
  mobile: '',
  country: 'US',
  state: 'AL',
  city: '',
  zipcode: '',
  address1: '',
  address2: '',
  user: 0,
  userId: 0,
  created: 0,
  updated: 0,
  status: AddressState.Normal,
});

export const useEditAddress = () => {
  const router = useRouter();
  /** vuerouter参数 */
  const { addressId } = useUrlSearchParams('history') as {addressId: string};
  /** 判断是否修改 */
  const isEditMode = computed(() => Boolean(addressId));
  /** 表单校验器 */
  const validator = new Schema(addressDescriptor);
  /** 表单实例 */
  const form = ref(getDefaulfFormAddress());
  /** 国家相关 */
  const { getCountryInfoById } = useCountry();
  /** 展示的国家信息 */
  const displayCountryInfo = computed(() => getCountryInfoById(form.value.country));
  /** 国家的电话前缀 */
  const phonePrefix = computed(() => displayCountryInfo.value?.phonePrefix);
  /** showDialog */
  const { showDialog } = useDialog();
  onBeforeMount(async () => {
    if (isEditMode.value) {
      form.value = await getAddressDetailById(addressId);
    }
  });
  /** 保存手机号码 */
  const saveAddress = async (formData): Promise<void> => {
    // 校验表单
    const [validateErr] = await to(validator.validate(formData));
    if (validateErr) {
      return showDialog({
      // @ts-ignore
        content: validateErr.errors[0].message || '表单未填写完整，请检查',
      });
    }
    // if (!isValidate) return;

    // wx.showLoading({
    //   title: '提交中.',
    // });

    // 判断是否新增还是修改
    const fn = isEditMode.value ? editUserAddress : addUserAddress;
    const [err] = await to(fn(formData));
    if (err) {
      throw err;
    }
    router.back();
  };

  return { form, getDefaulfFormAddress, phonePrefix, saveAddress };
};

export const useAddressList = () => {
  const router = useRouter();
  const addressList: Ref<AddressItem[]> = ref([]);
  const pageParams = ref({
    pageSize: 30,
    pageIndex: 1,
  });
  // todo 分页逻辑
  const { setForm } = useOrder();
  const { mode } = useUrlSearchParams('history') as {mode: AddressListMode};

  const clickAddress = (address: AddressItem) => {
    if (mode === AddressListMode.Select) {
      setForm('address', address);
      router.back();
    }
  };
  onBeforeMount(async () => {
    addressList.value = await getAddressList(pageParams.value);
  });
  return {
    addressList,
    pageParams,
    clickAddress,
  };
};
