export const BASE_URL = 'https://hub-beast.api.gblinker.com/';
export const PORDUCTION_BASE_URL = 'https://hub-beast.api.gblinker.com/';
export const APP_CODE = 'A001';
export const APP_TITLE = '格布小包';
export const TABBAR_SELECTED_INDEX = 'TABBAR_SELECTED_INDEX';
export const TOKEN_KEY = 'USER_LOGIN_TOKEN';
// 检查session有效时间
export const CHECK_SESSION_EXPIRED = 'CHECK_SESSION_EXPIRED';
export const CARGO_TYPE = [{
  label: '电子产品（带电）',
  value: 1,
}, {
  label: '化妆品（粉末、液体）',
  value: 2,
}, {
  label: '普货（不带电、无粉末非液体）',
  value: 3,
},
];
export const USER_INFO = 'USER_INFO_CACHE_20210617';
export const STATE_CACHE = 'COUNTRY_STATE_CACHE';
// 用户选择地址缓存
export const USER_SELECT_ADDRESS = 'USER_SELECT_ADDRESS';
// 用户选择线路缓存
export const USER_SELECT_EXPRESS = 'USER_SELECT_EXPRESS';

export const HAS_MOBILE = 'USER_HAS_AUTH_MOBILE';
export const USER_ID = 'USER_ID_CACHE';

// 进入小程序初始化query
export const INIT_QUERY = 'INIT_QUERY_CACHE';

export const IS_RELOAD_HOME = 'IS_RELOAD_HOME';
// 新手教程记录
export const HAS_COMPLETE_TUTORIAL = 'HAS_COMPLETE_TUTORIAL_20210724';

// 用户手机号码
export const USER_MOBILE = 'USER_MOBILE_CACHE';

// 用户点击包裹缓存, 用户显示包裹列表的小红点
export const USER_CLICK_PACKAGE_CACHE = 'USER_CLICK_PACKAGE_CACHE:';


// 配送地址文件
export const COUNTRY_STATE_ENDPOINT = 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json';

// 应用分享图片
export const APP_SHARE_IMAGE = 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/d0c2a5512e6538a4d87a6b616463c400.jpg';
