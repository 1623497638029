import { AddressItem } from '@/types/address';
import { defineStore } from 'pinia';
import { getDefaultAddress } from '@/api/address';
import { to } from '@/hooks/to';

type State = {
  defaultAddress: AddressItem | null
};
export const useAddressStore = defineStore('address', {
  state: (): State => ({
    defaultAddress: null,
  }),
  actions: {
    async fetchDefaultAddress() {
      const [err, res] = await to(getDefaultAddress());
      if (err) {
        this.defaultAddress = null;
      }
      this.defaultAddress = res;
    },
  },
});
