import Schema from 'async-validator';
import { to } from './to';
import { useStore } from '@/stores/module/order';
import { storeToRefs } from 'pinia';
import {
  confirmOrder,
  getPkgInfo,
  updateLogisticsCode,
  getOrderList,
  confirmPackageOrder,
  submitPackageOrder,
} from '@/api/order';
import { useJumpPage } from '@/hooks/jumpPage';
import { orderDescriptor } from '@/config/form-validator-descriptor';
import { AddressItem } from '@/types/address';
import { useDefaultAddress } from './address';
import { OrderCompleteEnum } from '@/types/router';
import { useUrlSearchParams, useDebounceFn } from '@vueuse/core';
import { onBeforeMount, onUnmounted, ref, Ref, watch } from 'vue';
import { PckInfoRes } from '@/types/order';
import { useDialog } from './dialog';
import { USER_CLICK_PACKAGE_CACHE } from '@/config/index';
import { useLoading } from './loading';
import { ToastTypeIconClass, useToast } from './toast';
import { useUser } from './user';
import { formatDate, promiseCache } from '@/libs/utils';
import { getCountryConfig } from '@/api/country';
import * as Base64 from 'js-base64';
import router from '@/router';

/** 获取用户地址 */
export const setAddress = async (data: AddressItem | null) => {
  const orderState = useStore();
  orderState.setForm('address', data);
  return data;
};
const QUICK_REMARK = [
  { desc: '拆除物品商业包装，减小体积', value: '可拆外包装' },
  { desc: '内含电', value: '含电' },
  { desc: '含磁', value: '含磁' },
  { desc: '含液体', value: '含液体' },
];
const updatePackageLogisticsCode = async ({ id, logisticsCode }) => {
  if (!id) {
    throw '入参错误，缺少id';
  }
  if (!logisticsCode) {
    throw '请填写快递单号';
  }
  const regex = /\p{sc=Han}/gu;
  const isInvalid = regex.test(logisticsCode);
  if (isInvalid) {
    // wx.showToast({ title: '您输入的快递单号似乎不是一个正确的快递单号', icon: 'none' });
    throw '您输入的快递单号似乎不是一个正确的快递单号';
  }
  // wx.showLoading({ title: '请稍等' });
  const res = await updateLogisticsCode({
    id,
    logisticsCode,
  });
  return res;
};
export const useOrder = () => {
  const { isLogin } = useUser();
  const { showDialog } = useDialog();
  const { showLoading, hideLoading } = useLoading();
  const { showToast } = useToast();
  const orderState = useStore();
  const { form, showProduct } = storeToRefs(orderState);
  const { navOrderCompleteList } = useJumpPage();
  watch(
    () => form.value.address?.country || '',
    (val) => {
      form.value.country = val;
      form.value.expressId = '';
    },
    {
      deep: true,
      immediate: true,
    }
  );

  /** 校验表单 */
  const preCheckOrder = async () => {
    const validator = new Schema(orderDescriptor);
    const [err] = await to(validator.validate(form.value));
    if (err) {
      // @ts-ignore
      throw err.errors[0].message;
    }
    const regex = /\p{sc=Han}/gu;
    const isInvalid = regex.test(form.value.logisticsCode);
    if (isInvalid) {
      throw '您输入的快递单号似乎不是一个正确的快递单号';
    }
    if (form.value.country !== form.value.address?.country) {
      throw '您的收件人国家(地区)似乎与所选收件地址的国家(地区)不一致';
    }
    if (!form.value.isAgree) {
      throw '您需要先同意转运规则条约';
    }
    if (showProduct.value) {
      const products =
        JSON.parse(localStorage.getItem('products') || '[]') || [];
      if (!products.length) {
        throw '请输入物品信息';
      }
      let price = 0;
      for (const item of products) {
        price += item.quantity * item.price;
      }
      if (price < 15) {
        throw '物品申报总金额不能小于15元,请返回修改';
      }
      form.value.products = products;
    }
    form.value.remark = `${form.value.selectRemark.join('; ')}; ${
      form.value.remark
    }`;
  };

  /** 下单 */
  const submitOrder = async () => {
    const [validatedErr] = await to(preCheckOrder());
    if (validatedErr) {
      return showDialog({ content: validatedErr as string });
    }
    showLoading();
    const [err, data] = await to(confirmOrder(form.value));
    hideLoading();
    if (err) {
      showDialog({ content: err as string });
      throw err;
    }
    showToast('下单成功');
    resetForm();
    navOrderCompleteList({
      id: data.id,
      type: OrderCompleteEnum.Order,
    });
  };
  const resetForm = async () => {
    orderState.resetForm();
    if (isLogin.value) {
      const { defaultAddress, fetchDefaultAddress } = useDefaultAddress();
      await fetchDefaultAddress();
      setAddress(defaultAddress.value);
    }
  };
  return {
    form,
    preCheckOrder,
    setForm: orderState.setForm,
    submitOrder,
    resetForm,
    showProduct,
    QUICK_REMARK,
  };
};

export const useOrderComplete = () => {
  const { id, type } = useUrlSearchParams('history');
  const packageInfo: Ref<PckInfoRes | null> = ref(null);
  const logisticsCode = ref('');
  const initPckInfo = async () => {
    packageInfo.value = await getPkgInfo(id);
    for (const item of packageInfo.value.packageAttr) {
      item.is_clicked =
        localStorage.getItem(
          `${USER_CLICK_PACKAGE_CACHE}:${item.package_attr_num}`
        ) || false;
    }
  };
  const updateLogisticsCode = async () => {
    await updatePackageLogisticsCode({
      id: packageInfo.value?.id,
      logisticsCode: logisticsCode.value,
    });
  };
  return {
    type,
    logisticsCode,
    packageInfo,
    initPckInfo,
    updateLogisticsCode,
  };
};

export const useOrderList = () => {
  const { showLoading, hideLoading } = useLoading();
  const { showDialog } = useDialog();
  const { showToast } = useToast();
  const { navOrderDetail, navToOrderComfirm } = useJumpPage();
  const dialogVisiable = ref(false);
  const keyword = ref('');
  const curNavKey = ref('all');
  const logisticsCode = ref('');
  const curOrder: Ref<any> = ref(null);
  let isLoading = false;
  let hasMore = true;

  const pageIndex = ref(1);
  const pageSize = ref(10);
  const unpaidTotal = ref(0);
  const packageList: Ref<any> = ref([]);

  const getPageData = async () => {
    if (!hasMore) {
      return;
      // alert('已经到底了');
    }
    if (isLoading) return;
    isLoading = true;
    showLoading();
    const { list, unpaidTotalNum } = await getOrderList({
      pageIndex: pageIndex.value,
      pageSize: pageSize.value,
      search: keyword.value,
      statusPay: curNavKey.value,
    });
    hideLoading();
    packageList.value = [...packageList.value, ...list];
    hasMore = list.length >= pageSize.value;
    if (hasMore) {
      pageIndex.value = pageIndex.value + 1;
    }
    isLoading = false;
    unpaidTotal.value = unpaidTotalNum;
  };

  const resetPage = (clearKeyword = true) => {
    if (clearKeyword) {
      keyword.value = '';
    }
    hasMore = true;
    selectPackageIds.value = [];
    isLoading = false;
    packageList.value = [];
    pageIndex.value = 1;
    selectCurrentUnpaid.value = false;
    selectPackagePrice.value = '';
  };

  const clearSearch = async () => {
    keyword.value = '';
    resetPage();
    await getPageData();
  };

  const updateCode = async () => {
    showLoading();
    const [err] = await to(
      updatePackageLogisticsCode({
        id: curOrder.value.id,
        logisticsCode: logisticsCode.value,
      })
    );
    if (err) {
      hideLoading();
      return showDialog({ content: err as string });
    }
    closeLogisticsModal();
    resetPage();
    await getPageData();
    hideLoading();
  };

  const openLogisticsModal = (orderItem) => {
    dialogVisiable.value = true;
    curOrder.value = orderItem;
  };
  const closeLogisticsModal = () => {
    dialogVisiable.value = false;
    logisticsCode.value = '';
    curOrder.value = null;
  };
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const keywordChange = useDebounceFn(async () => {
    resetPage(false);
    await getPageData();
  }, 1500);

  const payPackageIds: Ref<String[]> = ref([]);
  const showPayment = ref(false);
  const batchPay = () => {
    // payPackageIds.value = selectPackageIds.value;
    // showPayment.value = true;
    if (!selectPackageIds.value.length) {
      showToast('请选择需要支付的包裹', 'warn' as ToastTypeIconClass);
      return;
    }
    console.log('立即支付');
    navToOrderComfirm(Base64.encode(selectPackageIds.value.join(',')));
  };

  const selectPackageIds: Ref<String[]> = ref([]);
  const selectPackagePrice = ref('');
  const selectCurrentUnpaid = ref(false);

  const selectPackage = (item) => {
    console.log(packageList.value, item);
    item.checked = !item.checked;
    let count = 0;
    setTimeout(() => {
      selectPackageIds.value = packageList.value
        .map((item) => {
          if (item.checked) {
            count = count + item.price;
            return `${item.id}`;
          }
          return undefined;
        })
        .filter((item) => Boolean(item));
      console.log(selectPackageIds.value);
      selectPackagePrice.value = count.toFixed(2);
    }, 0);
  };
  const closePayment = () => {
    showPayment.value = false;
  };
  const paySuccess = () => {
    closePayment();
    console.log(payPackageIds.value);
    if (payPackageIds.value.length === 1) {
      return navOrderDetail(payPackageIds.value[0]);
    }
    payPackageIds.value = [];
    selectPackageIds.value = [];
    selectPackagePrice.value = '0.00';
    isLoading = false;

    resetPage();
    getPageData();
  };
  const startPay = async (id: number) => {
    payPackageIds.value = [`${id}`];
    // showPayment.value = true;
    navToOrderComfirm(Base64.encode(payPackageIds.value.join(',')));
  };
  // 全选
  const selectCurrentPage = () => {
    selectCurrentUnpaid.value = !selectCurrentUnpaid.value;
    let count = 0;
    setTimeout(() => {
      selectPackageIds.value = packageList.value
        .map((item) => {
          item.checked = selectCurrentUnpaid.value;
          if (item.checked) {
            count = count + item.price;
            return `${item.id}`;
          }
          return undefined;
        })
        .filter((item) => Boolean(item));
      selectPackagePrice.value = count.toFixed(2);
    }, 0);
  };

  return {
    payPackageIds,
    showPayment,
    selectPackageIds,
    selectPackagePrice,
    dialogVisiable,
    logisticsCode,
    keyword,
    unpaidTotal,
    curNavKey,
    packageList,
    getPageData,
    clearSearch,
    updateCode,
    openLogisticsModal,
    closeLogisticsModal,
    keywordChange,
    batchPay,
    selectPackage,
    resetPage,
    startPay,
    paySuccess,
    closePayment,
    selectCurrentPage,
    selectCurrentUnpaid,
  };
};

export const showDeclareTips = () => {
  const { showDialog } = useDialog();
  showDialog({
    content:
      '国际跨境物流中，包裹会有丢件的风险。我司（以及客服）无法保证货品100%不会丢失。所以我们真诚的建议您对您的订单进行保价，保价金额超过100元，平台会收取保价金额的 3% 作为保价费；当保价金额低于100元，则不收取保价费。运输中如遇包裹丢失：全额运费退还，并按您所声明的保价金额进行赔偿。我司承诺保多少赔付多少，赔付时保价金额作为赔付的唯一标准。',
    btns: [
      {
        text: '我知道了',
      },
    ],
  });
};

export const useOrderPay = () => {
  const payPackageIds: Ref<string[]> = ref([]);
  const showPayment = ref(false);
  const { navToOrderComfirm } = useJumpPage();
  const startPay = async (id: Number) => {
    payPackageIds.value = [`${id}`];
    // showPayment.value = true;
    navToOrderComfirm(Base64.encode(payPackageIds.value.join(',')));
  };
  return {
    payPackageIds,
    showPayment,
    startPay,
  };
};

const cacheCountryConfig = promiseCache(getCountryConfig);
/**
 * 支付订单确认
 * @returns
 */
export const useOrderComfirm = () => {
  const countryList = {};

  const selectCouponId = ref(null);
  const hasCoupon = ref(false);
  const selectdCoupon = ref({
    checked: false,
    id: null,
    coupon_type: '',
    coupon_amount: 0,
  });
  const paymentInfo = ref({
    coupon_amount: '-',
    pay_order_amount: '-',
    pay_order_id: 0,
    user_balance: 0,
    selected_user_coupon_ids: [],
  });

  const couponsList: Ref<any[]> = ref([]);
  const packages: Ref<any[]> = ref([]);
  const packageIds: Ref<Number[]> = ref([]);

  const { showLoading, hideLoading } = useLoading();

  const getPackages = async () => {
    const postData = {
      package_ids: packageIds.value,
    };
    if (selectdCoupon.value.id) {
      Object.assign(postData, {
        user_coupon_ids: [selectdCoupon.value.id],
      });
    }
    showLoading();
    const res = await confirmPackageOrder(postData).finally(() =>
      hideLoading()
    );
    const {
      package_info: list,
      selected_user_coupon_ids: selectCouponIds,
      user_coupon: userCoupons,
    } = res;
    packages.value.length = 0;
    selectdCoupon.value = {
      checked: false,
      id: null,
      coupon_type: '',
      coupon_amount: 0,
    };
    hasCoupon.value = false;
    selectCouponId.value = null;
    for (const item of list) {
      packages.value.push(
        Object.assign({}, item, {
          country_icon: countryList[item.country].icon,
          checked: false,
        })
      );
    }
    couponsList.value.length = 0;
    for (const item of userCoupons.available_coupons) {
      item.expiration_time = formatDate('yyyy.mm.dd', item.expiration_time);
      item.effective_time = formatDate('yyyy.mm.dd', item.effective_time);
      item.availabled = true;
      const isChecked = selectCouponIds.includes(item.id);
      item.checked = isChecked;

      item.coupon = JSON.stringify(item);
      if (isChecked) {
        selectdCoupon.value.checked = true;
        selectCouponId.value = item.id;
        selectdCoupon.value.id = item.id;
        selectdCoupon.value.coupon_type = item.coupon_type;
        selectdCoupon.value.coupon_amount = item.coupon_amount;
        hasCoupon.value = true;
      }
      couponsList.value.push(item);
    }
    for (const item of userCoupons.disabled_coupons) {
      item.expiration_time = formatDate('yyyy.mm.dd', item.expiration_time);
      item.effective_time = formatDate('yyyy.mm.dd', item.effective_time);
      item.availabled = false;
      item.checked = false;
      item.message = item.disabled_message.join('、');
      item.coupon = JSON.stringify(item);
      couponsList.value.push(item);
    }
    paymentInfo.value.coupon_amount = res.coupon_amount;
    paymentInfo.value.pay_order_amount = res.pay_order_amount;
    paymentInfo.value.pay_order_id = res.pay_order_id;
    paymentInfo.value.user_balance = res.user_balance;
    paymentInfo.value.selected_user_coupon_ids = res.selected_user_coupon_id;
    hideLoading();
    return;
  };

  const getStates = async () => {
    const countrys = await cacheCountryConfig();
    for (const item of countrys) {
      countryList[item.id] = item;
    }
  };

  onBeforeMount(async () => {
    const { ids } = useUrlSearchParams('history') as { ids: string };
    packageIds.value = Base64.decode(ids)
      .split(',')
      .map((v) => Number(v));
    await getStates();
    await getPackages();
  });

  onUnmounted(() => {
    console.log('onBeforeUnmounted');
  });
  const { showDialog } = useDialog();
  const { navToOrderCheckout } = useJumpPage();
  /**
   * 支付
   * @returns
   */
  const gotoPay = async () => {
    showLoading();
    const postData = {
      pay_order_id: paymentInfo.value.pay_order_id,
      auto_pay: 1,
    };
    const [err, res] = await to(submitPackageOrder(postData));
    hideLoading();
    if (err) {
      showDialog({ content: err as string });
      throw err;
    }
    if (res.pay_order_status && res.pay_order_status == 1) {
      router.replace({
        name: 'OrderList',
      });
      return;
    }
    navToOrderCheckout(paymentInfo.value.pay_order_id);
  };
  return {
    selectCouponId,
    couponsList,
    paymentInfo,
    packages,
    getPackages,
    selectdCoupon,
    hasCoupon,
    gotoPay,
  };
};
