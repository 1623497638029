import { Rules } from 'async-validator';
export const orderDescriptor: Rules = {
  country: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请选择收件国家或地区');
      }
      return true;
    },
  },
  logisticsCode: {
    type: 'string',
    required: false,
  },
  address: {
    type: 'object',
    required: true,
    validator: (rule: Rules, value: string) => {
      if (!value) {
        return new Error('请先选择收件人');
      }
      return true;
    },
  },
  declareAmount: {
    type: 'number',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入保价金额');
      }
      if (isNaN(Number(value))) {
        return new Error('保价金额需要为数字');
      }
      return true;
    },
  },
  expressId: {
    type: 'number',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请选择运输线路');
      }
      return true;
    },
  },
};

export const addressDescriptor: Rules = {
  firstname: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入收件人姓名');
      }
      return true;
    },
  },
  mobile: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (value && value.length > 50) {
        return new Error(`电话号码不能超过50个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },
  zipcode: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入邮编');
      }
      if (value.length > 10) {
        return new Error(`邮编不能超过10个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },

  state: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请选择州');
      }
      return true;
    },
  },
  city: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入城市');
      }
      if (value.length > 20) {
        return new Error(`城市不能超过20个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },
  address1: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入地址信息');
      }
      if (value.length > 100) {
        return new Error(`地址不能超过100个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },
};

export const chargeDescriptor: Rules = {
  amount: {
    type: 'number',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请选择充值金额');
      }
      if (isNaN(Number(value))) {
        return new Error('充值金额需为数字');
      }
      return true;
    },
  },
  card_number: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入卡号');
      }
      if (value && value.length > 40) {
        return new Error(`卡号不能超过40个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },
  expiry: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('过期时间不能为空');
      }
      value = value.replace(/\s/g, '');
      if (value.length > 7) {
        return new Error(`过期时间不能超过7个字符。当前长度为${value.length}`);
      }
      return true;
    },
  },
  cvv: {
    type: 'string',
    required: true,
    validator: (rule: any, value: string) => {
      if (!value) {
        return new Error('请输入CVV2码');
      }
      if (!/^\d{3,6}$/.test(value)) {
        return new Error('CVV2码只能输入3-6位数字');
      }
      return true;
    },
  },
};
